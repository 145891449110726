import cn from 'classnames';
import styles from './Projects.module.scss';
import { Title } from '@/components/Title';
import { Gallery } from '@/components/Gallery';
import { ProjectType } from '@/types';
import { Button } from '@/components/Button';
import { useTranslations } from 'next-intl';
import { FramerBlock } from '@/components/FramerBlock';
import { Arrow } from '@/components/Arrow';

type ProjectsProps = {
  className?: string;
};

const projects: ProjectType[] = [
  {
    img: '/webp/cloud.webp',
    imgMob: '/webp/cloud-mob.webp',
    link: 'https://cloud.ru/',
    title: 'cases.cloudru',
  },
  {
    img: '/webp/dreamisland.webp',
    imgMob: '/webp/dreamisland-mob.webp',
    link: 'https://dreamisland.ru/',
    title: 'cases.dreamisland',
  },
  {
    img: '/webp/console-cloud.webp',
    imgMob: '/webp/console-cloud-mob.webp',
    link: 'https://console.cloud.ru/',
    title: 'cases.console',
  },
  {
    img: '/webp/koreana.webp',
    imgMob: '/webp/koreana-mob.webp',
    link: 'https://koreanalight.ru/',
    title: 'cases.koreana',
  },
  {
    img: '/webp/dream-beach-club.webp',
    imgMob: '/webp/dream-beach-club-mob.webp',
    link: 'https://dbc.dreamisland.ru/',
    title: 'cases.dbc',
  },
  {
    img: '/webp/legends.webp',
    imgMob: '/webp/legends-mob.webp',
    link: 'https://8legends.ai/',
    title: 'cases.legends',
  },
  {
    img: '/webp/tma.webp',
    imgMob: '/webp/tma-mob.webp',
    link: 'https://t.me/InfiniteLegends_bot',
    title: 'cases.tma',
  },
  {
    img: '/webp/zeta.webp',
    imgMob: '/webp/zeta-mob.webp',
    link: 'https://zeta.kz/',
    title: 'cases.zeta',
  },
  {
    img: '/webp/moscow.webp',
    imgMob: '/webp/moscow-mob.webp',
    link: 'https://moscow-hall.ru/',
    title: 'cases.moscow',
  },
];

export function Projects({ className }: ProjectsProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations();

  const handleGithubClick = () => {
    window.open('https://github.com/WouterLab');
  };

  return (
    <div className={wrapperClassName} id="cases">
      <FramerBlock
        transition={0.6}
        variants={{
          visible: { transform: 'translateY(0%)', opacity: 1 },
          hidden: { transform: 'translateY(20%)', opacity: 0 },
        }}
        className={styles.frame}
      >
        <Gallery items={projects} className={styles.gallery} />
        <div className={styles.buttonWrapper}>
          <Button
            text="Github"
            onClick={handleGithubClick}
            customIcon={<Arrow className={styles.arrow} />}
            className={styles.button}
            animatedIcon
          />
        </div>
      </FramerBlock>
      <div className={styles.info}>
        <Title text={t('cases.title')} className={styles.title} />
        <img src="/webp/keyboard.webp" alt="keyboard" className={styles.img} />
        <div className={styles.empty} />
      </div>
    </div>
  );
}
